import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/auth/Login.vue";
import store from "../store";
import appInsights from "../services/appInsights";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/reset",
    name: "Reset",
    component: () => import("../views/reset/Reset.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("../views/forgotpassword/Forgot.vue"),
  },
  {
    path: "",
    component: () => import("../views/layout/LoggedInLayout.vue"),
    children: [
      {
        title: "Dashboard",
        path: "",
        name: "Dashboard",
        component: () => import("../views/dashboard/Dashboard.vue"),
      },
      {
        title: "Devices",
        path: "/devices",
        name: "devices",
        component: () => import("../views/devices/Register.vue"),
      },
      {
        title: "Map",
        path: "/map",
        name: "map",
        component: () => import("../views/map/Map.vue"),
      },
      {
        title: "Changelog",
        path: "/changelog",
        name: "changlog",
        component: () => import("../views/changelog/Changelog.vue"),
      },
      {
        title: "FaceFaces",
        path: "/faces",
        name: "face",
        component: () => import("../views/face/Faces.vue"),
      },
      {
        title: "Incidents",
        path: "/faces/incidents",
        name: "Incidents",
        component: () => import("../views/incident/Incidents.vue"),
      },
      {
        title: "New Incident",
        path: "/faces/incidents/new",
        name: "NewIncident",
        props: true,
        component: () => import("../views/incident/Form.vue"),
      },
      {
        title: "Incident",
        path: "/faces/incidents/:id(\\d+)",
        name: "incident",
        component: () => import("../views/incident/Incident.vue"),
      },
      {
        title: "Alerts",
        path: "/faces/alerts",
        name: "alerts",
        component: () => import("../views/alerts/alerts.vue"),
      },
      {
        title: "Alert",
        path: "/faces/alerts/:site_id/:id",
        name: "alert",
        component: () => import("../views/alerts/alert.vue"),
      },
      {
        title: "Events",
        path: "/events",
        name: "events",
        props: true,
        component: () => import("../views/events/Events.vue"),
      },
      {
        title: "Event",
        path: "/events/:id",
        props: true,
        name: "event",
        component: () => import("../views/events/Event.vue"),
      },
      // {
      //   title: 'Settings',
      //   path: '/settings',
      //   name: 'settings',
      //   component: () => import('../views/settings/Settings.vue'),
      // },
      {
        title: "Blacklist",
        path: "/blacklist",
        name: "blacklist",
        props: true,
        component: () => import("../views/blacklist/Blacklist.vue"),
      },
      {
        title: "Vehicle",
        path: "/vehicle/:registration",
        name: "vehicle",
        component: () => import("../views/blacklist/Vehicle.vue"),
      },
      {
        title: "NewBlacklist",
        path: "/blacklist/new",
        name: "newBlacklist",
        component: () => import("../views/blacklist/NewBlacklist.vue"),
      },
      {
        title: "ReportsClaims",
        path: "/reports/claims",
        name: "reportsClaims",
        props: true,
        component: () => import("../views/reports/ReportsClaims.vue"),
      },
      {
        title: "ReportsFaces",
        path: "/reports/faces",
        name: "reportsFaces",
        props: true,
        component: () => import("../views/reports/ReportsFaces.vue"),
      },
      // {
      //   title: 'Staging',
      //   path: '/staging',
      //   name: 'staging',
      //   component: () => import('../views/staging/Staging.vue'),
      // },
      {
        title: "Claims",
        path: "/claims",
        name: "claims",
        props: true,
        component: () => import("../views/claims/processing/Claims.vue"),
      },
      {
        title: "ViewClaim",
        path: "/claims/view",
        name: "viewclaim",
        props: (route) => ({
          id: route.query.id,
          client_id: route.query.client_id,
        }),
        // props: true,
        component: () => import("../views/claims/view/Claim.vue"),
      },
      {
        title: "NewClaim",
        path: "/claims/new",
        name: "newclaim",
        props: true,
        component: () => import("../views/claims/new/NewClaim.vue"),
      },
      {
        title: "NewDriveoff",
        path: "/claims/new/driveoff",
        name: "newdriveoff",
        component: () => import("../views/claims/new/CreateDriveoff.vue"),
      },
      {
        title: "NewNmop",
        path: "/claims/new/nmop",
        name: "newnmop",
        component: () => import("../views/claims/new/CreateNmop.vue"),
      },
      {
        title: "NewCnaf",
        path: "/claims/new/cnaf",
        name: "newcnaf",
        component: () => import("../views/claims/new/CreateCnaf.vue"),
      },
      {
        title: "Sites",
        path: "/sites",
        name: "sites",
        component: () => import("../views/sites/Sites.vue"),
      },
      {
        title: "NewSite",
        path: "/sites/new",
        name: "newSite",
        component: () => import("../views/sites/NewSite.vue"),
      },
      {
        title: "2fa",
        path: "/2fa",
        name: "2fa",
        component: () => import("../views/auth/2fa.vue"),
      },
      {
        title: "Authenticate",
        path: "/twoFactor",
        name: "twoFactorAuth",
        props: true,
        component: () => import("../views/auth/Authenticate.vue"),
      },
      {
        title: "Site",
        path: "/sites/:id",
        name: "site",
        props: true,
        component: () => import("../views/sites/site/Site.vue"),
      },
      // {
      //   title: "SiteGroups",
      //   path: "/sites/groups",
      //   name: "siteGroups",
      //   component: () => import("../views/sites/Groups.vue"),
      // },
      {
        title: "ClientWhitelist",
        path: "/whitelist/client",
        name: "clientWhitelist",
        component: () => import("../views/whitelist/Client.vue"),
      },
      {
        title: "GlobalWhitelist",
        path: "/whitelist/global",
        name: "globalWhitelist",
        component: () => import("../views/whitelist/Global.vue"),
      },
      {
        title: "Cameras",
        path: "/sites/:id/cameras",
        name: "siteCameras",
        component: () => import("../views/sites/site/devices/cameras/Cameras"),
      },
      {
        title: "Camera",
        path: "/camera/:id",
        name: "camera",
        props: true,
        component: () =>
          import("../views/sites/site/devices/cameras/Camera.vue"),
      },
      {
        title: "NewCamera",
        path: "/camera/new",
        name: "newcamera",
        props: true,
        component: () =>
          import("../views/sites/site/devices/cameras/Create.vue"),
      },
      {
        title: "Evidence",
        path: "/claims/evidence",
        props: true,
        name: "evidence",
        component: () => import("../views/claims/evidence/Evidence.vue"),
      },
      {
        title: "ManageEvidence",
        path: "/claims/evidence/:id",
        name: "manageEvidence",
        component: () => import("../views/claims/evidence/ManageEvidence.vue"),
      },
      {
        title: "Documentation",
        path: "/docs",
        name: "Documentation",
        component: () => import("../views/guides/Documentation.vue"),
      },
      {
        title: "VideoGuides",
        path: "/guides",
        name: "videoGuides",
        component: () => import("../views/guides/VideoGuides.vue"),
      },
      {
        title: "GlobalStatistics",
        path: "/stats/global",
        name: "globalStatistics",
        component: () => import("../views/statistics/Global.vue"),
      },
      {
        title: "ClientStatistics",
        path: "/stats/client",
        name: "clientStatistics",
        component: () => import("../views/statistics/ClientStatistics.vue"),
      },
      {
        title: "SiteStatistics",
        path: "/stats/site",
        name: "siteStatistics",
        component: () => import("../views/statistics/SiteStatistics.vue"),
      },
      {
        title: "VehicleSearch",
        path: "/vehicle-search",
        name: "vehcileSearch",
        component: () => import("../views/vehiclesearch/VehicleSearch.vue"),
      },
      {
        title: "WorstSites",
        path: "/worst-sites",
        name: "worstsites",
        component: () => import("../views/dashboard/Customised/WorstSites.vue"),
      },
      {
        title: "Customer Service Messages",
        path: "/customer-service",
        name: "customerservice",
        component: () => import("../views/customerservice/CustomerService.vue"),
      },
      {
        title: "Ordering POS",
        path: "/ordering-pos",
        name: "orderingPos",
        component: () => import("../views/ordering/Ordering.vue"),
      },
      {
        title: "Ordering POS",
        path: "/ordering-pos/:id",
        props: true,
        name: "order",
        component: () => import("../views/ordering/Order.vue"),
      },
      //catchAll 404
      {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: () => import("../views/PageNotFound.vue"),
      },

      // {
      //   title: 'Documents',
      //   path: '/sites/:id/documents',
      //   name: "siteDocuments",
      //   component: () => import('../views/sites/site/data/Documents.vue')
      // }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  console.info("Route Name: ", to.name);
  if (
    to.name !== "Login" &&
    to.name !== "Reset" &&
    to.name !== "Forgot" &&
    !store.state.auth.status.loggedIn
  ) {
    console.info(
      "Not logged in and not loading login page, redirecting!",
      to.name
    );
    return next({ name: "Login" });
  }

  next();
});

router.afterEach((to, from, next) => {
  const savedPosition = {
    left: window.scrollX,
    top: window.scrollY,
  };

  appInsights.trackPageView({ name: to.name, uri: to.path });

  localStorage.setItem("savedPosition", JSON.stringify(savedPosition));
});

export default router;
