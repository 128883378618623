export default {
  "languages": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])}
  },
  "global": {
    "barriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barriers"])},
    "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
    "driveoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive Off"])},
    "nmop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Means of Payment"])},
    "cnaf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Not at Fault"])},
    "cnafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive Off (Customer Not at Fault)"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type..."])},
    "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklist"])},
    "unblacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblacklist"])},
    "blacklisted_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklisted At"])},
    "make": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "fuelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Type"])},
    "colour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "select_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a File"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "staging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staging"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Filter"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "important_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Message!"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whitelist"])},
    "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data to show"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "generate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Generate ", _interpolate(_named("text"))])},
    "show": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show ", _interpolate(_named("text"))])},
    "hide": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hide ", _interpolate(_named("text"))])},
    "client": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Client ", _interpolate(_named("text"))])},
    "site": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Site ", _interpolate(_named("text"))])},
    "all_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Available"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "claim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Claim ", _interpolate(_named("text"))])},
    "select": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("text"))])},
    "enter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter ", _interpolate(_named("text"))])},
    "global": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Global ", _interpolate(_named("text"))])},
    "select_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Client"])},
    "escalated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalated"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
  },
  "navigation": {
    "top": {
      "applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
    },
    "side": {
      "register_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Device"])},
      "face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face"])},
      "faces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faces"])},
      "incidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incidents"])},
      "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
      "face_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Claims"])},
      "new_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Claim"])},
      "view_claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Claims"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
      "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklist"])},
      "view_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Blacklist"])},
      "national_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National Blacklist"])},
      "add_new_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Vehicle"])},
      "sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites"])},
      "claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claims"])},
      "customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service"])},
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
      "view_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Clients"])},
      "new_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Clients"])},
      "installers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installers"])},
      "vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles"])},
      "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands"])},
      "accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting"])},
      "view_sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Sites"])},
      "new_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Site"])},
      "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "new_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Group"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
      "view_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Users"])},
      "new_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "permission_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Groups"])},
      "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
      "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modules"])},
      "view_modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Modules"])},
      "new_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Module"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
      "monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring"])},
      "whitelists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whitelists"])},
      "client_whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Whitelist"])},
      "global_whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Whitelist"])},
      "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
      "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
      "help_centre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Centre"])},
      "staging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staging"])},
      "manage_claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Claims"])},
      "video_guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Guides"])},
      "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
      "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
      "vehicle_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Search"])},
      "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation"])},
      "favourite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourite"])},
      "no_fav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Favourites"])}
    }
  },
  "login": {
    "auth_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign-in to your account and continue to the dashboard."])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "errors": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username is required!"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required!"])},
      "auth_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email address or password entered is incorrect. Please try again."])}
    }
  },
  "reset": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "confirm_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password is required!"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password reset failed. Please try again."])}
  },
  "devices": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Device"])}
  },
  "dashboard": {
    "select_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Site"])},
    "todays_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's Data"])},
    "todays-payments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " Payments"])},
    "todays-losses": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " Losses"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yesterday"])},
    "worst_site": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " Worst Site"])},
    "lost_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lost today"])},
    "worst_pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worst Pump"])},
    "claim_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim Count"])},
    "total_claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total claims"])},
    "paid_on_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid on site"])},
    "fuel_recovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Recovered"])},
    "this_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Year"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "total_parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total parking charges"])},
    "active_parking_sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Parking Sites"])},
    "being_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Being Recovered"])},
    "chart_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The below statistics reflect the current claims being processed. Tap on each field to view details."])},
    "money_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money Recovered"])},
    "recovered_on_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovered on Site"])},
    "losses_reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Losses Reported"])},
    "recovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery of Money"])},
    "money_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money Outstanding"])},
    "last": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last ", _interpolate(_named("text"))])},
    "this": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This ", _interpolate(_named("text")), " to date"])},
    "previous_year_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View previous data"])},
    "outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["from this time ", _interpolate(_named("text"))])},
    "blacklist_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events Today"])},
    "blacklist_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events this Week"])},
    "blacklist_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events this Month"])},
    "blacklist_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events this Year"])},
    "blacklist_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklisted Vehicles"])},
    "graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphs"])},
    "parking_revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Revenue"])},
    "potential_blacklist_savings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potential Blacklist Savings"])},
    "claims_reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claims Reported"])},
    "claims_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claims Value"])},
    "claims_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Claims"])},
    "blacklisted_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklisted Vehicles"])},
    "cancellations_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellations Per Month"])},
    "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load dashboard data"])},
    "currently_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently Processing"])},
    "last_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Year"])},
    "plp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potential Loss Prevented by Blacklist Alerts"])},
    "recovery_of_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery of Fuel"])},
    "fuel_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Outstanding"])},
    "weeks_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Week's Data"])},
    "months_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Month's Data"])},
    "years_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Year's Data"])},
    "last_years_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Year's Data"])},
    "last_twelve_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 12 Months"])},
    "cancellations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellations"])},
    "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last week"])},
    "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last month"])},
    "fuel_recovery_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load recovery of fuel data"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Revenue"])},
    "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklist Activity"])},
    "plp_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The figure below gives an estimate value of revenue saved by blacklisting vehicles."])}
  },
  "pagination": {
    "showing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("showing")), " of ", _interpolate(_named("to")), " of ", _interpolate(_named("total")), " entries"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
  },
  "claims": {
    "processing": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "vehicle-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Registration"])},
      "reference-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Number"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "driveoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive Off"])},
      "nmop-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Means of Payment"])},
      "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
      "actionable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actionable"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
      "nonactionable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Actionable"])},
      "hide-locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Locked"])},
      "received-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received on"])},
      "by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["by ", _interpolate(_named("site"))])},
      "progress-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress Message"])},
      "notes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No Notes"]), _normalize(["1 Note"]), _normalize([_interpolate(_named("count")), " Notes"])])},
      "appeals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No Appeals"]), _normalize(["1 Appeal"]), _normalize([_interpolate(_named("count")), " Appeals"])])},
      "payments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No Payments"]), _normalize(["1 Payment"]), _normalize([_interpolate(_named("count")), " Payments"])])},
      "has-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Payments?"])},
      "has-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Notes?"])},
      "outstanding_losses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding Losses"])},
      "vars_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vars Invoice"])},
      "nmop": {
        "add_second_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Second Fuel"])},
        "edit_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Fuel Information"])},
        "edit_second_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Second Fuel Information"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Change"])},
        "fuel_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated claim details"])},
        "fuel_change_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update fuel details"])}
      },
      "payment": {
        "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Payment"])},
        "payment_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load payments"])},
        "vendor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " Vendor"])},
        "amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " Amount"])},
        "received": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " Received"])},
        "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
        "make_payment_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully created payment"])},
        "make_payment_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create payment. Please try again"])}
      }
    },
    "new": {
      "crime_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police/Crime Reference"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Claim"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please pick which type of claim you wish to create."])},
      "requirements": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Following are the informations required to create a ", _interpolate(_named("text")), " claim"])},
      "nmop": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Means of Payment"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A customer has dispensed fuel and is unable to make payment on site."])}
      },
      "driveoff": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive Off"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A customer has dispensed fuel and then driven off without making an attempt to or refusing to pay for the fuel."])}
      },
      "abandoned": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandoned Vehicle"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vehicle has been left abandoned your site for a long period of time"])}
      },
      "dreamoff": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dream Off"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An employee was unable to recover payment on site."])}
      },
      "nameAndAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and Address of Customer"])},
      "vehicleRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Registration"])},
      "fuelValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value of Fuel in £"])},
      "litres_of_fuel_dispensed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litres of Fuel dispensed"])},
      "pumpNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pump Number"])},
      "tillReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy of Till Receipt"])},
      "vehicleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Image"])},
      "cctv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCTV Footage"])},
      "vehicleDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of Vehicle"])},
      "vehicleDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days abandoned"])},
      "createNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Now"])},
      "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
      "select_a_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Site"])},
      "site_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a site"])},
      "registration_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not know the vehicle registration we will be unable to recover the fuel for you."])},
      "vehicle_make": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Make"])},
      "vehicle_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Model"])},
      "vehicle_colour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Colour"])},
      "find_make_model_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Make, Model and Color of Vehicle"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "type_of_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Fuel"])},
      "fuel_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Type"])},
      "event_on_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events on Site"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
      "litres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litres"])},
      "pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pump"])},
      "select_a_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Type"])},
      "petrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petrol"])},
      "diesel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesel"])},
      "adblue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AdBlue"])},
      "reddiesel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red Diesel"])},
      "add_new_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Fuel"])},
      "liquid_petroleum_gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquid Petroleum Gas (LPG)"])},
      "fuel_type_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid type for the fuel."])},
      "fuel_value_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid value for the fuel."])},
      "fuel_litres_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid litres for the fuel."])},
      "fuel_pump_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid pump for the fuel."])},
      "fuel_type_minimum_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide at least 1 fuel type."])},
      "very_important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very Important!"])},
      "name_and_address_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are unable to provide us with the full name and address of the driver we are unable to process this as a No Means of Payment and it will be converted to a full Drive Off by our administration team."])},
      "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])},
      "customer_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Email"])},
      "customer_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Mobile"])},
      "customer_postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Postcode"])},
      "locate_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locate Address"])},
      "select_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Address"])},
      "select_an_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an Address"])},
      "address_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 1"])},
      "address_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 2"])},
      "address_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 3"])},
      "address_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 4"])},
      "important_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Notice"])},
      "upload_as_much_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure to upload as much evidence as you can to support the claim. Having more evidence available increases our chances of recovering the fuel and reduces the need to contact you for further information in the future."])},
      "columns_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columns marked with <span class='text-danger'>*</span> are required"])},
      "evidence_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide a minimum of 1 vehicle image and 1 till receipt"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
      "evidence_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidence Type"])},
      "comments_on_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments on Evidence"])},
      "drive_off_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive Off Report"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "valid_evidence_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a valid evidence type."])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "please_provide_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a description of the No Means of Payment. Include as much information as you can such as:"])},
      "please_provide_description_driveoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a description of the drive off. Include as much information as you can such as:"])},
      "filling_cannister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was the customer filling a cannister?"])},
      "attempt_to_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did the customer make any attempt to pay?"])},
      "enter_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did the customer enter the store?"])},
      "asked_for_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was the customer asked if they had fuel?"])},
      "please_provide_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide as much information as you can about the event"])},
      "create_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create ", _interpolate(_named("text"))])},
      "error_with_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a problem with the information you have provided. Please see the error messages above for more information."])},
      "submitting_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitting Claim"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim Created Successfully"])},
      "claim_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reference number is"])},
      "min_3_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be at least 3 characters!"])},
      "max_12_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be no more than 12 characters!"])},
      "must_be_valid_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be valid time formatted as: "])},
      "creating_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a new claim..."])},
      "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading"])},
      "evidence_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evidence files"])},
      "file_format_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have attempted to upload an invalid file type. Please provide one of PNG, JPEG or PDF."])},
      "file_size_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can only accept images which are less than 25mb in size!"])},
      "total_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Outstanding"])},
      "remove_second_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Second Fuel Type"])},
      "add_new_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Evidence"])},
      "go_to_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Dashboard"])}
    }
  },
  "evidences": {
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "card_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The claims listed below are missing evidence which we require to be able to process them. Please submit this evidence as soon as possible to avoid delays or cancellations."])},
    "ready_to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready To Process"])},
    "awaiting_evidences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting Evidence"])},
    "select_a_claim_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a type"])},
    "update_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated evidence."])},
    "update_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update evidence."])},
    "vehicle_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of Vehicle"])},
    "till_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of Till Receipt"])},
    "transfer_of_liability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer of Liability"])},
    "dvla_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DVLA Response"])},
    "dvla_enquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DVLA Enquiry"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "manage_evidence": {
    "manage_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Claim"])},
    "claim_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim Details"])},
    "vehicle_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Details"])},
    "missing_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing Evidence. Please upload evidence and try again."])},
    "missing_vehicle_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently missing a vehicle image!"])},
    "missing_till_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently missing a till receipt image!"])},
    "missing_both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently missing a vehicle image and a till receipt image!"])},
    "ready_to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The claim is ready to process."])},
    "upload_evidences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Please upload at least one clear image of the vehicle and one clear image of the till receipt for further processing of the claim."])},
    "upload_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Evidence"])},
    "uploaded_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Evidence"])},
    "vehicle_image_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Image Upload"])},
    "till_receipt_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till Receipt Upload"])},
    "no_image_selected_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an image before uploading."])},
    "vehicle_uploaded_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully uploaded vehicle image."])},
    "vehicle_uploaded_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload vehicle image."])},
    "receipt_uploaded_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully uploaded till receipt."])},
    "receipt_uploaded_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload till receipt."])},
    "till_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till Receipt"])},
    "provide_claim_cancellation_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a reason for cancelling the claim."])},
    "cancel_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Claim"])},
    "valid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid reason."])},
    "claim_cancelled_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim cancelled successfully."])},
    "failed_cancelling_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed cancelling claim"])},
    "process_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This claim can now be processed."])},
    "failed_updating_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed updating claim. Please try again."])},
    "status_ready_to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Process"])},
    "labels": {
      "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel"])},
      "liters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liters"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
      "pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pump"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
      "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plate"])}
    }
  },
  "events": {
    "filters": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "click_to_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to Open"])},
      "vehicle_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Registration"])},
      "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
      "select_a_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Site"])},
      "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera"])},
      "select_a_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Camera"])},
      "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
      "select_a_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Direction"])},
      "leaving_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaving Site"])},
      "entering_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entering Site"])},
      "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
      "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
      "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
      "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    },
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklist"])},
    "unblacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblacklist"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press"])},
    "event_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or click outside of the image to close this popup!"])},
    "events_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load events."])},
    "select_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a status"])}
  },
  "event": {
    "blacklist_entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklist Entries"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "reason_for_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Blacklist"])},
    "date_blacklisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Blacklisted"])},
    "claims_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claims Outstanding"])},
    "customer_service_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service Message"])},
    "important_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Notice"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this vehicle is blacklisted the customer service message will not show on your VARS Display."])},
    "drive_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive Off"])},
    "no_means_of_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Means of Payment"])},
    "fly_tipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fly Tipping"])},
    "theft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theft"])},
    "aggressive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggressive"])},
    "plate_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plate Mismatch"])},
    "vars_test_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VARS Test Vehicle"])},
    "vehicle_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Information"])},
    "claim_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim Information"])},
    "reason_for_watchlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Watchlist"])},
    "money_owed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money Owed"])},
    "n_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "csm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you would like the display to notify staff of something when this vehicle comes onto site but do not wish to blacklist it, place a message below."])},
    "csm_danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the vehicle is blacklisted this message will not show on the display. Please use the notes if you wish for it to display on a blacklist alert!"])},
    "add_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Note"])},
    "no_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Notes to Show"])},
    "no_csm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Customer Service Message"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "camera_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load Cameras"])},
    "event_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load event details."])},
    "valid_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid note."])}
  },
  "blacklist": {
    "create_new_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Blacklist"])},
    "single_blacklist_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Blacklist Entry"])},
    "multiple_blacklist_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple Blacklist Entry"])},
    "add_to_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Blacklist"])},
    "remove_from_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from Blacklist"])},
    "select_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a reason"])},
    "warning_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ensure the CSV file has 2 coulmns, one for the plate and one for the reason the plate is blacklisted."])},
    "csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV File"])},
    "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure"])},
    "modal_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will remove the vehicle from your blacklist."])},
    "remove_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Remove it"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added vehicle to blacklist."])},
    "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to add vehicle to blacklist. Please try again."])},
    "preview_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview Data"])},
    "blacklist_modal_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a reason for blacklisting"])},
    "unblacklist_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully removed vehicle from blacklist."])},
    "unblacklist_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to remove vehicle from blacklist."])},
    "load_blacklist_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load blacklist. Please try again."])},
    "aggression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggression"])},
    "no_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide a reason to blacklist!"])},
    "invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide a valid reason to blacklist!"])},
    "clear_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Data"])},
    "total_entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Entries"])}
  },
  "whitelist": {
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "added_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added By"])},
    "added_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added On"])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "no_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notes to show"])},
    "global_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to load global whitelist."])},
    "client_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to load client whitelist."])}
  },
  "vehicle_notes": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Notes"])},
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
    "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to save note to vehicle. "])}
  },
  "reports": {
    "filter_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter ANPR Reports"])},
    "filter_reports_face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Face Reports"])},
    "claim_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Claim"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Week"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Month"])},
    "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Quarter"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Year"])},
    "generate_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Report"])},
    "num_claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Claims"])},
    "num_faces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Faces"])},
    "total_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Value"])},
    "total_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Paid"])},
    "total_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cancelled"])},
    "request_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request A Cancellation"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select A Reason"])},
    "cancel_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Cancellation Notice to Motorist?"])},
    "submit_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Request"])},
    "success_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully submitted cancellation request."])},
    "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to submit cancellation request."])},
    "filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I use this?"])},
    "filter_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the filters provided below to generate report on the claims you are interested in. The report will display below and you will be able to download it as an Excel document."])},
    "filter_text_face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the filters provided below to generate report on the faces detected you are interested in. The report will display below and you will be able to download it as an Excel document."])},
    "chart_overview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " Overview"])},
    "charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charts"])},
    "date_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Received"])},
    "date_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Paid"])},
    "cancel_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Reason"])},
    "sort_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort By"])}
  },
  "sites": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "cameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameras"])},
    "create_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Site"])},
    "address_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 1"])},
    "address_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 2"])},
    "town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Town or City"])},
    "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["County"])},
    "tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tier"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone Number"])},
    "finish_and_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish and Create"])},
    "site_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Information"])},
    "site_whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Whitelist"])},
    "site_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated site details."])},
    "site_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed saving changes to site. Please try again."])},
    "site_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load sites."])},
    "mailing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When considering adding a user to this mailing list, please consider the fact that they will receive any emails which are toggled on for this site."])},
    "display": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Display ", _interpolate(_named("text"))])},
    "rdiesel_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red Diesel Sign Ins"])},
    "refusal_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusal Sign Ins"])},
    "site": {
      "site_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Config"])},
      "sign_ins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Ins"])},
      "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
      "rdiesel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red Diesel"])},
      "refusal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusal"])},
      "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
      "site_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Overview"])},
      "lifetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lifetime"])},
      "portal_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal Only"])},
      "tablet_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablet Only"])},
      "parking_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Only"])},
      "full_vars_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Vars System"])},
      "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the file to view it."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "evidence_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidence Type"])},
      "no_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No images to preview"])},
      "site_install_pic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Installation Pictures"])},
      "file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Name"])},
      "upload_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Image"])},
      "image_upload_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image uploaded successfully"])},
      "image_upload_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload image. Please try again."])},
      "asset_upload_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully uploaded evidence!"])},
      "asset_upload_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload evidence."])}
    },
    "data": {
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
      "installPics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install Pictures"])},
      "signage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signage"])},
      "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands"])},
      "upload_install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Install Image"])},
      "upload_signage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Signage Image"])},
      "uploaded_signage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signage Images"])},
      "uploaded_install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Installation Images"])},
      "failed_to_upload_signage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload signage. Please try again."])},
      "delete_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Evidence"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
      "add_new_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Brand"])},
      "add_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Brand"])},
      "failed_to_load_brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load brands. Please try again."])},
      "failed_to_add_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to add brand to the site. Please try again."])},
      "failed_to_delete_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete brand from the site. Please try again."])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
      "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["page(s)"])},
      "show_all_pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all pages"])},
      "add_asset_to_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add asset to site"])},
      "add_asset_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLEASE MAKE SURE YOU VERIFY THE FILE BEFORE YOU UPLOAD IT. IF THE FILE IS SUSPICIOUS, PLEASE ASK THE SUPPORT TEAM TO VERIFY IT."])},
      "asset_upload_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully uploaded file!"])},
      "asset_upload_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload file. Please try again."])},
      "add_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Asset"])},
      "asset_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Type"])},
      "delete_asset_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will delete the asset and is not reversible?"])},
      "no_document_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No document uploaded."])}
    },
    "sign_ins": {
      "signins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Ins"])},
      "date_time_signedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/Time Signed In"])},
      "view_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Signature"])},
      "date_time_signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/Time Signed Out"])},
      "date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Range"])}
    },
    "settings": {
      "claim_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim Settings"])},
      "barriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barriers"])},
      "mailingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing List"])},
      "tab_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tab Settings"])},
      "dashboard_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Pin"])},
      "claims_success_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully "])}
    },
    "invoice": {
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Reference"])},
      "first_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Line"])},
      "due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total ", _interpolate(_named("text"))])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])}
    },
    "new": {
      "create_site_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully created new site."])},
      "create_site_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create site. Please try again."])},
      "load_clients_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to load list of clients."])},
      "site_ref_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load site references"])},
      "select_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Tier"])}
    },
    "mailing_list": {
      "add_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Mailing List"])},
      "current_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Users"])},
      "add_user_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added user to mailing list."])},
      "add_user_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to remove user from the mailing list."])},
      "remove_user_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully removed user from mailing list."])},
      "remove_user_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to remove user from mailing list."])}
    },
    "devices": {
      "camera_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera Mode"])},
      "display_update_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated display name"])},
      "display_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to updated display name. Please try again."])},
      "assigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assigned ", _interpolate(_named("text"))])},
      "last_authenticated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Authenticated"])},
      "last_ping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Ping"])},
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
      "alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THIS INFORMATION UPDATES ROUGHLY EVERY 15 SECONDS"])},
      "install_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install Date"])},
      "last_maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Maintenance"])},
      "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Number"])},
      "reverse_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverse Direction"])},
      "hide_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide from Displays"])},
      "error": {
        "camera_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load Camera details. If this problem persists, please contact support."])},
        "models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load Camera Models. If this problem persists, please contact support."])},
        "modes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load Camera Modes. If this problem persists, please contact support."])}
      }
    },
    "refusal": {
      "customer_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Description"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "refused_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused By"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])}
    },
    "temperature": {
      "fridges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fridges"])},
      "freezer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freezer"])},
      "fridge_temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fridge Temperature Sign Ins"])},
      "action_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action Taken"])},
      "beverages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beverages"])},
      "fridge_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load fridge data"])},
      "add_fridge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Fridge"])},
      "add_fridge_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added new fridge"])},
      "add_freezer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Freezer"])},
      "add_freezer_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added new freezer"])},
      "daily_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Food"])},
      "hot_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot Food"])},
      "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food"])},
      "food_temp_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load food temperature"])},
      "init_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial Quantity"])},
      "qty_wasted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity Wasted"])},
      "qty_remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity Remaining"])},
      "fridge_temp_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load fridge temperature"])},
      "freezer_temp_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load freezer temperature"])},
      "add_food_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Food Item"])},
      "new_food_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Food Item"])},
      "select_food_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select food type"])},
      "add_food_item_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added new food item to the list"])},
      "add_food_item_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to add new food item to the list"])},
      "initials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff Initials"])}
    }
  },
  "staging": {
    "staging_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staging Key"])},
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "assign_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Site"])},
    "assign_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Role"])},
    "select_a_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Site"])},
    "select_a_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Role"])},
    "provision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provision"])},
    "device_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Name"])}
  },
  "labels": {
    "claim_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim Type"])},
    "reported_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reported by"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "admin_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration Fee"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-Total"])},
    "payment_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Made"])},
    "tab_settings": {
      "lite_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lite Only"])},
      "staff_parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff Parking"])},
      "vehicle_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle History"])},
      "customer_parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Parking"])},
      "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
      "evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidence"])}
    },
    "parking": {
      "parking_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Settings"])},
      "fine_perc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine Percentage"])},
      "rates_perc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rates Percentge"])},
      "max_stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Stay"])},
      "no_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Return Policy"])}
    }
  },
  "csm": {
    "success_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated customer service message for"])},
    "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to updated customer service message."])}
  },
  "vehicle": {
    "blacklisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Blacklisted ", _interpolate(_named("text"))])}
  },
  "guides": {
    "video_guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Guides"])},
    "alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."])},
    "portal_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard - Portal Only"])},
    "create_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create A Claim"])}
  },
  "vehicle_search": {
    "alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Search lets you search for a vehicle activity across all the clients you have access to. To do so, you must provide the full registration number of the vehicle."])},
    "no_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Number of ", _interpolate(_named("text"))])},
    "system_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Logs"])}
  },
  "stats": {
    "one": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Means of Payment Escalated to Drive Off"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data below shows number of no means of payment claims that were escalated to drive off."])}
    },
    "two": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st letter sent for Drive Off"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data below shows number of Drive Off claims for which 1st letter has been sent to the registered keeper."])}
    },
    "three": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd letter sent for Drive Off"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data below shows number of Drive Off claims for which 2nd letter has been sent to the registered keeper."])}
    },
    "four": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Means of Payment raised to debt collection"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data below shows number of No Means of Payment claims that were raised to debt collection."])}
    },
    "five": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive Off raised to debt collection"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data below shows number of Drive Off claims that were raised to debt collection."])}
    },
    "six": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of blacklisted vehicles"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data below shows total number of vehicles blacklisted across all clients."])}
    },
    "seven": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANPR Sites"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "eight": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablet only sites"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "nine": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal only sites"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "global": {
      "alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Statistics gives a overview of the forecourts health and performance across all the clients user has access to."])}
    }
  },
  "worst_sites": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worst Sites"])},
    "money_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money Lost"])},
    "recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money Recovered"])},
    "alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The table below lists the top worst sites across all sites for the month."])},
    "worst_pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worst Pump"])}
  }
}